import React, { useEffect, useState } from "react";
import Intro from "../Intro";
import ReactPageScroller from "react-page-scroller";
import Contents from "../Contents";
import Media from "../Media";
import SideNav from "../SideNav";
import LinkNav from "../LinkNav";
import PreRegistration from "../PreRegistration";
import CommunityEvent from "../CommunityEvent";
import { useModalStore } from "../../store/ModalStore";
import Footer from "../Footer";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import Partner from "../Partner";

interface ScrollProps {
  scrollerState: boolean;
}

const Main = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const { isModalOpen } = useModalStore();
  const [isYouTubePlay, setIsYouTubePlay] = useState(false);
  const [scrollerState, setScrollerState] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const [isKr, setIsKr] = useState(false);

  useEffect(() => {
    if (navigator.language === "ko-KR" || navigator.language === "ko") {
      setIsKr(true);
    } else setIsKr(false);
    console.log("isKr", isKr);
  }, []);
  console.log("navigator.language", navigator.language);
  const handlePageChange = (number: number) => {
    setCurrentPage(number);
    setScrollerState(false);
    if (!isKr && number === 3) {
      setIsYouTubePlay(true);
    } else if (!isKr && number !== 3) {
      setIsYouTubePlay(false);
    }
  };

  const handleBeforePageChange = (number: number) => {
    setCurrentPage(number);
    setScrollerState(false);
  };

  const handleScrollUnavailable = () => {
    if ((isKr && currentPage === 3) || (!isKr && currentPage === 4)) {
      setScrollerState(true);
    }
  };

  return (
    <Wrapper>
      {isMobile ? (
        <>
          <SideNav
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            isKr={isKr}
          />
          <Intro />
          {/* <PreRegistration /> */}
          <CommunityEvent />
          {!isKr ? <Partner isYouTubePlay={isYouTubePlay} /> : <></>}
          <Contents />
          <Media />
          <LinkNav scrollerState={scrollerState} />
        </>
      ) : (
        <ScrollerBox scrollerState={scrollerState}>
          <SideNav
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            isKr={isKr}
          />
          <ReactPageScroller
            customPageNumber={currentPage}
            onBeforePageScroll={handleBeforePageChange}
            handleScrollUnavailable={handleScrollUnavailable}
            renderAllPagesOnFirstRender
            blockScrollDown={isModalOpen}
            blockScrollUp={isModalOpen}
          >
            <Intro />
            {/* <PreRegistration /> */}
            <CommunityEvent />
            {!isKr && <Partner isYouTubePlay={isYouTubePlay} />}
            <Contents />
            <Media />
          </ReactPageScroller>
          <LinkNav scrollerState={scrollerState} />
        </ScrollerBox>
      )}
      <Footer />
    </Wrapper>
  );
};
export default Main;

const ScrollerBox = styled.div<ScrollProps>`
  margin-top: ${({ scrollerState }) => (scrollerState ? "-170px" : "0")};
  transition: all ease 0.2s;
`;

const Wrapper = styled.div`
  height: auto;
`;
