import React from "react";
import styled from "styled-components";
import ContentsSlider from "./ContentsSlider";
import {
  contentsBg,
  contentsTitle,
  contentsTitleMobile,
  howtoplayhover,
  HowtoPlay,
} from "../../assets/img";
import { useTranslation } from "react-i18next";
import { theme } from "../../styles/theme";
import { useMediaQuery } from "react-responsive";

const Contents = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({
    query: "(max-width: 640px)",
  });
  return (
    <ContentsBox id="contentWrapper">
      <h2>
        <img src={isMobile ? contentsTitleMobile : contentsTitle} />
      </h2>
      <p>{t("Carrie_Life")}</p>
      <ContentsSlider />
      <HowtoPlayLinkImg
        img={HowtoPlay}
        hoverImg={howtoplayhover}
        href={
          "http://blog.carrieverse.io/portfolio-category_cat/game-guide-en/"
        }
        target="_blank"
      />
    </ContentsBox>
  );
};

export default Contents;

const ContentsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: url(${contentsBg}) no-repeat center center;
  background-size: cover;
  > h2 {
    width: 100%;
    margin-bottom: 5px;
  }
  > p {
    margin-bottom: 50px;
    width: 100%;
    font-size: 2.2rem;
    text-shadow: 0.5px 1px 1px rgba(0, 0, 0, 0.2);
    color: #fff;
  }
  @media ${theme.mq.tablet} {
    padding: 50px 0 75px;
    width: 100%;
    height: auto;
    h2 img {
      max-width: 280px;
    }
  }
  @media ${theme.mq.mobile} {
    h2 {
      margin-bottom: 20px;
      img {
        max-width: 143px;
      }
    }
    > P {
      margin-bottom: 30px;
      font-size: 16px;
    }
  }
`;
const HowtoPlayLinkImg = styled.a<{ img: any; hoverImg: any }>`
  width: 289px;
  height: 72px;
  margin-top: 56px;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: top;

  cursor: pointer;
  &:hover {
    background-image: url(${({ hoverImg }) => hoverImg});
  }

  @media ${theme.mq.mobile} {
    width: 192px;
    height: 48px;
  }
`;
