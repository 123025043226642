import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getBrowserLang } from '../../utils/common';
import { theme } from '../../styles/theme';
import Dropdown from './Dropdown';

interface ScrollProps {
  scrollerState: boolean;
}

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterWrap>
      <ul>
        <li>{t('Carrieverse Co., Ltd')}</li>
        <li>{t('CEO : Yoon Jeong-hyun')}</li>
        <li>
          {t(
            '35-6, Samseong-ro 85-gil, Gangnam-gu, Seoul, Republic of Korea (06196)'
          )}
        </li>
        <li>{t('Business registration number : 415-88-02120')}</li>
      </ul>
      <Dropdown />
      <p>© CARRIEVERSE CORP.ALL RIGHTS RESERVED.</p>
    </FooterWrap>
  );
};

export default Footer;

const FooterWrap = styled.footer`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  right:0;
  padding:32px 0px 24px 0px;
  font-size:1.4rem;
  color:#fff;
  background-color: rgba(23, 69, 127, 0.9);
  box-sizing: border-box;
  transition: all ease 0.2s;
  ul{
    display: flex;
    justify-content: center;
    width: 100%;
      li{
        position: relative;
        margin:0 10px;
        :not(:first-child)::after{
          content:'';
          position: absolute;
          top:50%;
          left:-10px;
          width: 1px;
          height: 10px;
          background:#fff;
          transform: translateY(-50%);
        }
      }
    }
  }
  p{
    width: 100%;
    text-align: center;
    font-family: "SpoqaHanSansNeo-Regular";
   
  }
  @media ${theme.mq.tablet}{
    position: static;
    padding:32px 0 48px;
    height:auto;
    font-size: 13px;
    ul{
      flex-wrap:wrap;
      margin-bottom:14px;
      li{
        margin:0 0 8px;
        padding:0 10px;
        width:100%;
        box-sizing: border-box;
        text-align:center;
        :nth-child(-n + 2){
          width:50%;
          text-align: right;
        }
        :nth-child(2){
          text-align: left;
          ::after{
            left:0 !important;
          }
        }
        :not(:nth-child(2))::after{
          display: none;
        }
        
      }
    }
    p{
      margin-top: 24px;
    }
  }
`;
