import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { LIST } from './list';
import { plus, x, hover_plus } from '../../assets/img';
import { theme } from '../../styles/theme';

const Dropdown = () => {
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = (index: number) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      {LIST.map((item, index) => (
        <Category key={index}>
          {openDropdown === index && (
            <DropdownMenu>
              {item.items.map((item, index) => (
                <DropdownItem key={index}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.title}
                  </a>
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
          <CategoryLabel
            onClick={() => toggleDropdown(index)}
            isOpen={openDropdown === index}
          >
            {item.label}
            <RotatableIcon
              src={openDropdown === index ? x : plus}
              alt=""
              width={12}
              height={12}
              rotated={openDropdown === index}
              isOpen={openDropdown === index}
            />
          </CategoryLabel>
        </Category>
      ))}
    </Container>
  );
};

const Container = styled.div`
  font-family: 'SpoqaHanSansNeo-Regular';
  display: flex;
  gap: 24px;
  margin: 24px 0 24px 0px;
  @media ${theme.mq.tablet} {
    flex-direction: column;
    gap: 12px;
    margin: 0;
  }
`;

const Category = styled.div`
  position: relative;
  font-family: 'SpoqaHanSansNeo-Regular';
`;

const CategoryLabel = styled.div<{ isOpen: boolean }>`
  font-family: 'SpoqaHanSansNeo-Regular';
  height: 34px;
  border-radius: 4px;
  border: ${(props) => (props.isOpen ? '' : '1px solid #fff')};
  background: ${(props) =>
    props.isOpen ? 'rgba(255, 255, 255, 0.75)' : '#2e578b'};
  padding: 8px 16px;
  color: ${(props) => (props.isOpen ? '#17457F' : '#FFF')};
  display: flex;
  width: 220px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: ${(props) => (props.isOpen ? '500' : '400')};
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.75);
    color: #17457f;
    border: none;

    img {
      content: ${(props) =>
        props.isOpen ? `url(${x})` : `url(${hover_plus})`};
    }
  }
  @media ${theme.mq.tablet} {
    height: 40px;
    width: 224px;
    font-size: 13px;
    &:hover {
      background: ${(props) =>
        props.isOpen ? 'rgba(255, 255, 255, 0.75)' : '#2e578b'};
      color: ${(props) => (props.isOpen ? '#17457F' : '#FFF')};
      border: ${(props) => (props.isOpen ? '' : '1px solid #fff')};

      img {
        content: none;
      }
    }
  }
`;

const RotatableIcon = styled.img<{ rotated: boolean; isOpen: boolean }>`
  width: 12px;
  height: 12px;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: ${(props) => (props.rotated ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

const DropdownMenu = styled.div`
  position: absolute;
  bottom: 100%;
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(3px);
  margin-bottom: 4px;
  z-index: 10;
  height: auto;
  transition: opacity 0.3s ease;
`;

const DropdownItem = styled.div`
  font-family: 'SpoqaHanSansNeo-Regular';
  color: #859ab4;
  a {
    font-size: 14px;
    color: #17457f;
    cursor: pointer;
    @media ${theme.mq.tablet} {
      font-size: 13px;
    }

    &:hover {
      color: #17457f;
      font-weight: 700;
      line-height: normal;
    }
  }
`;

export default Dropdown;
