import React from "react";
import styled from "styled-components";
import {
  communityEventBg,
  communityEventDiscordDefault,
  communityEventDiscordDefaultTablet,
  communityEventDiscordHover,
  communityEventDiscordHoverTablet,
  communityEventItem1,
  communityEventItem1Tablet,
  communityEventItem2,
  communityEventItem2Tablet,
  communityEventMetaDefault,
  communityEventMetaDefaultTablet,
  communityEventMetaHover,
  communityEventMetaHoverTablet,
  communityEventTiktokDefault,
  communityEventTiktokDefaultTablet,
  communityEventTiktokHover,
  communityEventTiktokHoverTablet,
  communityEventTitle,
  communityEventTitleMobile,
  communityEventYoutubeDefault,
  communityEventYoutubeDefaultTablet,
  communityEventYoutubeHover,
  communityEventYoutubeHoverTablet,
  preRegistrationSubTitleBg,
  preRegistrationSubTitleBgMobile,
  carrieverseCommunity,
  carrieverseCommunityMo,
  communityFrame,
  commuityHover,
} from "../../assets/img";
import { useTranslation } from "react-i18next";
import { theme } from "../../styles/theme";
import { useMediaQuery } from "react-responsive";

const snsLinkArray = [
  {
    defaultImg: communityEventDiscordDefault,
    hoverImg: communityEventDiscordHover,
    src: "https://discord.gg/carrieverseofficial",
  },
  {
    defaultImg: communityEventYoutubeDefault,
    hoverImg: communityEventYoutubeHover,
    src: "https://www.youtube.com/@CarrieVerseOfficial",
  },
  {
    defaultImg: communityEventMetaDefault,
    hoverImg: communityEventMetaHover,
    src: "https://www.facebook.com/CarrieverseGame",
  },
  {
    defaultImg: communityEventTiktokDefault,
    hoverImg: communityEventTiktokHover,
    src: "https://www.tiktok.com/@carrieverseofficial",
  },
];
const snsLinkArrayTablet = [
  {
    defaultImg: communityEventDiscordDefaultTablet,
    hoverImg: communityEventDiscordHoverTablet,
    src: "https://discord.gg/carrieverseofficial",
  },
  {
    defaultImg: communityEventYoutubeDefaultTablet,
    hoverImg: communityEventYoutubeHoverTablet,
    src: "https://www.youtube.com/@CarrieVerseOfficial",
  },
  {
    defaultImg: communityEventMetaDefaultTablet,
    hoverImg: communityEventMetaHoverTablet,
    src: "https://www.facebook.com/CarrieverseGame",
  },
  {
    defaultImg: communityEventTiktokDefaultTablet,
    hoverImg: communityEventTiktokHoverTablet,
    src: "https://www.tiktok.com/@carrieverseofficial",
  },
];
const CommunityEvent = () => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 640px)",
  });
  return (
    <CommunityEventWrapper id="communityEventWrapper">
      <CommunityEventOpacityBg>
        <CommunityEventTitle>
          <img
            src={isMobile ? carrieverseCommunityMo : carrieverseCommunity}
            alt="community event title image"
          />
        </CommunityEventTitle>
        {/* <CommunityEventSubTitleBg>
          <CommunityEventSubTitle>
            {t("Until_Official_Launch")}
          </CommunityEventSubTitle>
        </CommunityEventSubTitleBg> */}
        {/* <CommunityEventCardWrapper>
          <img
            src={isTablet ? communityEventItem1Tablet : communityEventItem1}
            alt="community event image"
          />
          <img
            src={isTablet ? communityEventItem2Tablet : communityEventItem2}
            alt="community event image"
          />
        </CommunityEventCardWrapper> */}
        {/* {isTablet ? (
          <a href="http://blog.carrieverse.io/">
            <CommunityLinkImg>Community</CommunityLinkImg>
          </a>
        ) : (
          <></>
        )} */}
        {isTablet ? (
          <>
            <CommunityLinkImg
              img={communityFrame}
              hoverImg={commuityHover}
              href={"http://blog.carrieverse.io/"}
              target="_blank"
            />
          </>
        ) : (
          <></>
        )}

        <StoreLinkArea>
          {isTablet
            ? snsLinkArrayTablet.map((card, index) => {
                return (
                  <LinkImg
                    key={index}
                    img={card.defaultImg}
                    hoverImg={card.hoverImg}
                    href={card.src}
                    target="_blank"
                  />
                );
              })
            : snsLinkArray.map((card, index) => {
                return (
                  <LinkImg
                    key={index}
                    img={card.defaultImg}
                    hoverImg={card.hoverImg}
                    href={card.src}
                    target="_blank"
                  />
                );
              })}
        </StoreLinkArea>

        {isTablet ? (
          <></>
        ) : (
          <CommunityLinkImg
            img={communityFrame}
            hoverImg={commuityHover}
            href={"http://blog.carrieverse.io/"}
            target="_blank"
          />
        )}
      </CommunityEventOpacityBg>
    </CommunityEventWrapper>
  );
};
export default CommunityEvent;

const CommunityEventWrapper = styled.div`
  width: 100vw;
  height: 100vh;

  background-image: url(${communityEventBg});
  background-size: cover;
  background-position: top;
  @media ${theme.mq.tablet} {
    width: 100%;
    height: auto;
  }
`;

const CommunityEventOpacityBg = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;

  background: rgba(61, 161, 253, 0.85);
  @media ${theme.mq.tablet} {
    position: relative;
    padding: 50px 0 75px;
    height: 454px;
  }
`;

const StoreLinkArea = styled.div`
  position: absolute;
  display: flex;
  gap: 20px;

  @media ${theme.mq.tablet} {
    margin-top: 77px;
    bottom: 72px;
  }
`;

const LinkImg = styled.a<{ img: any; hoverImg: any }>`
  width: 177px;
  height: 54px;

  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: top;

  cursor: pointer;
  &:hover {
    background-image: url(${({ hoverImg }) => hoverImg});
  }
  @media ${theme.mq.tablet} {
    width: 80px;
    height: 80px;
    background-size: cover;
  }
  @media ${theme.mq.mobile} {
    width: 48px;
    height: 48px;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CommunityEventCardWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 80px 0 0 0;
  max-width: 960px;
  img {
    max-width: calc(50% - 10px);
  }
  @media ${theme.mq.tablet} {
    flex-direction: column;
    gap: 16px;
    padding: 0 20px;
    img {
      width: 320px;
      max-width: 100%;
    }
  }
  @media ${theme.mq.mobile} {
    margin-top: 40px;
  }
`;

const CommunityEventTitle = styled.div`
  position: absolute;
  top: 22%;
  transform: translate(0, -22%);
  img {
    max-width: 764px;
  }
  @media ${theme.mq.tablet} {
    top: 10%;
    transform: translate(0, -10%);
    img {
      max-width: 320px;
    }
  }
  @media ${theme.mq.mobile} {
    img {
      max-width: 320px;
    }
  }
`;

const CommunityEventSubTitleBg = styled.div`
  display: flex;
  align-items: center;

  margin: 20px 0 0 0;

  width: 390px;
  height: 40px;

  background-image: url(${preRegistrationSubTitleBg});
  background-size: cover;
  background-position: top;

  @media ${theme.mq.mobile} {
    margin-top: 10px;
    width: 250px;
    height: 26px;
    background-image: url(${preRegistrationSubTitleBgMobile});
  }
`;
const CommunityLinkImg = styled.a<{ img: any; hoverImg: any }>`
  margin-top: 250px;
  width: 288px;
  height: 72px;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: top;

  cursor: pointer;
  &:hover {
    background-image: url(${({ hoverImg }) => hoverImg});
  }

  @media ${theme.mq.tablet} {
    margin-top: 0px;
    width: 288px;
    height: 72px;
  }
  @media ${theme.mq.mobile} {
    margin-top: 0px;
    width: 192px;
    height: 48px;
  }
`;

const CommunityEventSubTitle = styled.span`
  display: flex;
  align-items: center;

  margin: 0 0 0 20px;

  width: 362px;
  height: 34px;

  font-family: "MarkPro";
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 2.8rem;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.04em;
  color: #ffffff;
  text-shadow: 0.5px 1px 1px rgba(0, 0, 0, 0.2);
`;
