import styled from "styled-components";
import {
  btnGoogleStore,
  btnGoogleStoreM,
  btnPlayDefault,
  btnPlayHover,
  iconAppStore,
  introVideo,
} from "../../assets/img";

import React from "react";
import CommonModal from "../../components/common/modal/commonModal";
import useModal from "../../hooks/useModal";
import { theme } from "../../styles/theme";
import { useMediaQuery } from "react-responsive";
import ReactPlayer from "react-player";

const Intro = () => {
  const [isYouTubeModalOpen, openYouTubeModal, closeYouTubeModal] =
    useModal(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  return (
    <IntroWrapper id="introWrapper">
      <IntroOpacityBg>
        <PlayBtn
          onClick={() => {
            if (!isYouTubeModalOpen) {
              openYouTubeModal();
            } else {
              closeYouTubeModal();
            }
          }}
        ></PlayBtn>
        <video autoPlay loop muted playsInline>
          <source src={introVideo} type="video/mp4" />
        </video>
        <StoreBtnBox>
          <ul>
            <li>
              <a
                href=" https://play.google.com/store/apps/details?id=com.carrieverse.carriepark"
                target="_blank"
              >
                <img
                  src={isMobile ? btnGoogleStoreM : btnGoogleStore}
                  alt="Google Play link image"
                />
              </a>
            </li>
          </ul>
          {/* <p>You can play CarrieVerse with LD Player</p> */}
        </StoreBtnBox>
        {/*  모달 시작 */}
        {isYouTubeModalOpen && (
          <CommonModal
            width={isMobile ? "90%" : "1800px"}
            maxWidth={"90%"}
            minHeight={isMobile ? "30%" : "650px"}
            maxHeight={"90%"}
            height={isMobile ? "30%" : "1000px"}
            top={"50%"}
            left={"50%"}
            modalIsOpen={isYouTubeModalOpen}
            closeModal={closeYouTubeModal}
          >
            <ReactPlayerWrapper>
              <ReactPlayer
                url={"https://www.youtube.com/watch?v=wrp83coSzKk"}
                width="100%" // 플레이어 크기 (가로)
                height="100%" // 플레이어 크기 (세로)
                playing={true} // 자동 재생 on
                muted={false} // 자동 재생 on
                controls={true} // 플레이어 컨트롤 노출 여부
                light={false} // 플레이어 모드
                pip={true} // pip 모드 설정 여부
                loop={true}
                volume={1}
                quality={"1080"}
                config={{
                  youtube: {
                    playerVars: { showinfo: 1 },
                  },
                }}
                poster={
                  "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg"
                } // 플레이어 초기 포스터 사진
              />
            </ReactPlayerWrapper>
          </CommonModal>
        )}
        {/*  모달 끝 */}
      </IntroOpacityBg>
    </IntroWrapper>
  );
};
export default Intro;

const IntroWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const IntroOpacityBg = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;

  video {
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    object-fit: cover;
  }
  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }
`;

const StoreBtnBox = styled.div`
  position: absolute;
  bottom: 120px;
  left: 0;
  right: 0;
  z-index: 200;
  ul {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    li {
      padding: 0 10px;
      img {
        max-width: 100%;
      }
    }
  }
  p {
    font-size: 2rem;
    text-align: center;
    color: #fff;
  }
  @media ${theme.mq.tablet} {
    top: 50%;
    transform: translateY(30px);
    ul {
      flex-wrap: wrap;
      margin-bottom: 8px;
      li {
        padding: 4px 0;
        width: 100%;
        text-align: center;
        img {
          max-width: 100%;
        }
      }
    }
    p {
      font-size: 14px;
    }
  }
`;

const ReactPlayerWrapper = styled.div`
  width: 100%;
  height: 100%;
  // pointer-events: none;
`;

const PlayBtn = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;

  background-image: url(${btnPlayDefault});
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  border: 4px solid #fff;
  border-radius: 100%;
  cursor: pointer;
  z-index: 1;
  transition: all ease 0.3s;
  &:hover {
    background-image: url(${btnPlayHover});
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, 100px);
    content: "Play Cinematic";

    font-family: "MarkPro";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 51px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #ffffff;
    text-shadow: 0.5px 1px 1px rgba(0, 0, 0, 0.2);

    width: 400px;
  }
  @media ${theme.mq.tablet} {
    transform: translate(-50%, -150px);
    width: 72px;
    height: 72px;
    background-size: 40px;
    border: 3px solid #fff;
    &::after {
      top: -20px;
      width: 220px;
      font-size: 20px;
    }
  }
`;

const AppStoreModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 320px;
  height: 160px;
  font-size: 2.4rem;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(13px);
  border: 2px solid #fff;
  box-sizing: border-box;
  ::after {
    content: "";
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 69px;
    background: url(${iconAppStore}) no-repeat center center;
  }
  @media ${theme.mq.tablet} {
    width: 100%;
    font-size: 20px;
  }
`;
