export const MEDIA_DATA = [
  {
    id:1,
    light_src: "imgLight.png",
    media_src :"imgMediaItem01.png",
    media_tablet_src : "imgMediaItemTablet01.png"
  },
  {
    id:2,
    light_src: "imgLight.png",
    media_src :"imgMediaItem02.png",
    media_tablet_src : "imgMediaItemTablet02.png"
  },
  {
    id:3,
    light_src: "imgLight.png",
    media_src :"imgMediaItem03.png",
    media_tablet_src : "imgMediaItemTablet03.png"
  },
  {
    id:4,
    light_src: "imgLight.png",
    media_src :"imgMediaItem04.png",
    media_tablet_src : "imgMediaItemTablet04.png"
  },
  {
    id:5,
    light_src: "imgLight.png",
    media_src :"imgMediaItem05.png",
    media_tablet_src : "imgMediaItemTablet05.png"
  },
  {
    id:6,
    light_src: "imgLight.png",
    media_src :"imgMediaItem06.png",
    media_tablet_src : "imgMediaItemTablet06.png"
  },
]
export const MEDIA_ITEM_DATA = [
  {
    src: "https://www.youtube.com/shorts/v2fb39CY9rA",
  },
  {
    src: "https://www.youtube.com/watch?v=KdjJPR0hYJY",
  },
  {
    src: "https://www.youtube.com/watch?v=3JYlxrpfEoc",
  },
  {
    src: "https://www.youtube.com/watch?v=IsYOjbc90YQ",
  },
  {
    src: "https://www.youtube.com/watch?v=PBgJVNw8yzE",
  },
  {
    src: "https://www.youtube.com/watch?v=i2zPofwSkKg",
  }
]