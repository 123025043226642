export const LIST = [
  {
    label: 'Cling',
    items: [
      { title: 'Clingon', link: 'https://www.clingon.io/' },
      {
        title: 'Cling Wallet',
        link: 'https://play.google.com/store/apps/details?id=com.carrieverse.cling.wallet&hl=ko&gl=US&pli=1',
      },
      {
        title: 'Cling Swap',
        link: 'https://clingswap.io/',
      },
    ],
  },
  {
    label: 'CARRIEVERSE',
    items: [
      {
        title: 'CARRIEVERSE Official',
        link: 'http://carrieverse.com',
      },
      {
        title: ' CARRIEVERSE NFT',
        link: 'https://carrieverse.world/',
      },
      {
        title: 'CARRIEVERSE Web3',
        link: 'https://w3con.carrieverse.io/',
      },
    ],
  },
  {
    label: 'SUPERKOLA TACTICS',
    items: [
      {
        title: 'SUPERKOLA TACTICS Game',
        link: 'https://www.superkolatactics.io/',
      },
      {
        title: ' SUPERKOLA TACTICS Web3',
        link: 'https://w3con.superkolatactics.io/',
      },
    ],
  },
  {
    label: 'Dedollz',
    items: [{ title: 'Dedollz', link: 'https://dedollz.xyz/' }],
  },
];

const Dropdown = () => {
  return <div></div>;
};

export default Dropdown;
