import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled, { css } from "styled-components";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import useModal from "../../hooks/useModal";
import { SLIDER_DATA, SLIDER_DETAIL_DATA } from "./SlideData";
import {
  contentsSliderBg,
  slideDetailBg,
  imgDetailTitleMasking01,
  imgDetailTitleMasking02,
  imgDetailTitleMasking03,
  imgDetailTitleMasking04,
  imgDetailTitleMasking05,
  imgDetailTitleMasking06,
  iconDetailMasking01,
  iconDetailMasking02,
} from "../../assets/img";
import CommonModal from "../../components/common/modal/commonModal";
import { useTranslation } from "react-i18next";
import { getBrowserLang } from "../../utils/common";
import {theme} from "../../styles/theme";
import {useMediaQuery} from "react-responsive";

interface MaskType {
  maskType: number;
  lang:string
}

interface boxBgProps {
  boxBg: number;
  fontCustom: string;
  lang:string;
}
const ContentsSlider = () => {
  const { t } = useTranslation();
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    touchMove: true,
    autoplay: false,
    speed: 500,
    pauseOnHover: false,
    arrows: true,
    cssEase: "linear",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows:false,
        },
      },
    ],
  };

  const getImage = (imgName: string) => require(`../../assets/img/${imgName}`);
  const [isSlideModal, openlideModal, closelideModal] = useModal(false);
  const [modalNum, setModalNum] = useState({
    id: 0,
    imgSrc: "",
    titleText: "",
    i18nContentText: "",
    contentText: "",
    maskingSrc: "",
  });
  const [fontFamily, setFontFamily] = useState("NanumPenScript");
  const modalHandler = (modalId: number) => {
    if (!isSlideModal && !isTablet) {
      openlideModal();
      setModalNum({
        id: modalId,
        imgSrc: getImage(SLIDER_DETAIL_DATA[modalId - 1].imgSrc),
        titleText: SLIDER_DETAIL_DATA[modalId - 1].titleText,
        i18nContentText: SLIDER_DETAIL_DATA[modalId - 1].i18nContentText,
        contentText: SLIDER_DETAIL_DATA[modalId - 1].contentText,
        maskingSrc: SLIDER_DETAIL_DATA[modalId - 1].maskingSrc,
      });
    } else {
      closelideModal();
    }
  };
  const lang = getBrowserLang();


  useEffect(() => {
    if (lang === "en") {
      // 영어
      setFontFamily("NanumPenScript");
    }
    if (lang === "ko") {
      // 한국
      setFontFamily("NanumPenScript");
    }
    if (lang === "ja") {
      // 일본
      setFontFamily("JPMeiryob");
    }
    if (lang === "zh") {
      // 중국 간체
      setFontFamily("CN");
    }
    if (lang === "id") {
      // 인도네시아 인니어
      setFontFamily("ENHILeagueSpartan");
    }
    if (lang === "vi") {
      // 베트남어
      setFontFamily("VIDTPhudu");
    }
    if (lang === "th") {
      // 태국어
      setFontFamily("THKanit");
    }
  }, []);

  return (
    <SliderContents fontCustom={fontFamily}>
      <Slider {...settings}>
        {SLIDER_DATA.map((slider) => (
          <SlideBox
            key={slider.id}
            maskType={slider.maskType}
            lang={lang}
            onClick={() => {
              modalHandler(slider.id);
            }}
          >
            <img src={getImage(slider.imgSrc)} alt={`${slider.text} image`} />
            <p>#{t(slider.text)}</p>
            <img
              src={getImage(slider.maskSrc)}
              alt={`${slider.text} masking image`}
            />
          </SlideBox>
        ))}
      </Slider>
      {isSlideModal && (
        <CommonModal
          width={isTablet ? "calc(90% - 100px)" : "1000px"}
          minHeight={isTablet ? "auto" : "650px"}
          height={isTablet ? "auto" : "650px"}
          top={"50%"}
          left={"50%"}
          modalIsOpen={isSlideModal}
          closeModal={closelideModal}
          closeIcon={true}
        >
          <DetailBox boxBg={modalNum.id} fontCustom={fontFamily} lang={lang}>
            <strong>{t(modalNum.titleText)}</strong>
            <img src={modalNum.imgSrc} alt={`${modalNum.titleText} image`} />
            <p>{t(modalNum.i18nContentText)}</p>
          </DetailBox>
        </CommonModal>
      )}
    </SliderContents>
  );
};

export default ContentsSlider;

const SliderContents = styled.div<{ fontCustom: string }>`
  max-width:100%;
  font-family: ${({ fontCustom }) => {
    return fontCustom;
  }};
  .slick-slider {
    padding: 0 84px;
    width: 1234px;
    max-width: 100%;
    height: 350px;
    box-sizing: border-box;
    < div {
      max-height: 100%;
    }
  }
  .slick-slide {
    padding: 40px 0 25px;
    box-sizing: border-box;
  }
  
  .slick-dots{ 
    position: static;
    display: flex !important;
    justify-content: center;
    li{
      display: block;
      margin: 0 5px;
      width: 8px;
      height:8px;
      button{
        padding:0;
        width: 8px;
        height:8px;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius:100%;
        &::before {
          display: none;
        } 
      }
      &.slick-active button{
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
  
  @media ${theme.mq.tablet}{
    .slick-slider{
      height:auto;
    }
    .slick-slide {
      padding: 40px 10px 40px;
    }
  }
  @media ${theme.mq.mobile}{
    .slick-slider{
      padding:0;
    }
  }
`;
const SlideBox = styled.div<MaskType>`
  position: relative;
  padding: 20px 12px 16px;
  max-width: 332px;
  height: 277px;
  box-sizing: border-box;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  background: url(${contentsSliderBg}) no-repeat center top;
  :hover {
    cursor: pointer;
  }
  img:first-child {
    display: block;
    margin: auto;
    margin-bottom: 11px;
  }
  img:last-child {
    position: absolute;
    ${(props) => {
      if (props.maskType === 1) {
        return css`
          top: -38px;
          left: 50%;
          transform: translateX(-50%);
        `;
      } else if (props.maskType === 2) {
        return css`
          top: -23px;
          left: 50%;
          transform: translateX(-50%);
        `;
      } else if (props.maskType === 3) {
        return css`
          bottom: -22px;
          right: -12px;
        `;
      } else if (props.maskType === 4) {
        return css`
          bottom: -19px;
          left: 23px;
        `;
      } else if (props.maskType === 5) {
        return css`
          top: -24px;
          right: -10px;
        `;
      }
    }}
  }
  p {
    ${({lang})=>{
      if(lang == "id"){return css`
        padding-top:7px;
        font-size:2rem;
      `}
      else if(lang == "vi"){return css`
        padding-top:1px;
        font-size:2.2rem;
      `}
      else if(lang == "zh"){return css`
        padding-top:1px;
        font-size:2.2rem;
      `}
      else if(lang == "ja"){return css`
        padding-top:3px; 
        font-size:2rem;
      `}
      else if(lang == "th"){return css`
        margin-top:-1px;
        font-size:2.4rem;
      `}
      else{return css`font-size:2.8rem;`}
    }}
  }
  
  @media ${theme.mq.tablet}{
    height:auto;
    max-width:320px;
    img:first-child{
      width:100%;
    }
    img:last-child {
      ${(props) => {
        if (props.maskType === 3) {
          return css`
          bottom: -20px;
          right: -12px;
        `;
        } else if (props.maskType === 4) {
          return css`
          bottom: -20px;
          left: -20px;
        `;
        }
        }
      }}
    }
  }
`;
const DetailBox = styled.div<boxBgProps>`
  font-family: ${({ fontCustom }) => {
    return fontCustom;
  }};
  position: relative;
  padding: 30px 20px 0;
  width: 100%;
  height: 100%;
  font-weight: 300;
  background: url(${slideDetailBg}) center;
  ::after {
    content: "";
    position: absolute;
    bottom: 8px;
    right: -30px;
    width: 60px;
    height: 60px;
    background: url(${iconDetailMasking02}) no-repeat center;
  }
  ::before {
    content: "";
    position: absolute;
    top: -38px;
    left: -78px;
    width: 172px;
    height: 60px;
    background: url(${iconDetailMasking01}) no-repeat center;
  }
  strong {
    position: absolute;
    bottom: -25px;
    right: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 60px 0 40px;
    height: 80px;
    ${(props) => {
      if (props.boxBg === 1) {return css`width: 303px`;}
      else if(props.boxBg === 2) {return css`width: 216px`;}
      else if(props.boxBg === 3) {return css`width: 222px`;}
      else if(props.boxBg === 4) {return css`width: 238px`;}
      else if(props.boxBg === 5) {return css`width: 226px`;}
      else if(props.boxBg === 6) {return css`width: 195px`;}

    }};
    ${({lang})=>{
      if(lang == "ja"){return css`
        font-size:3rem;
        padding-top:5px;
      `}
      else if(lang == "zh"){return css`font-size:3rem;`}
      else if(lang == "id"){return css`padding-top:9px; font-size:3.2rem;`}
      else if(lang == "th"){return css`font-size:3.6rem;`}
      else if(lang == "vi"){return css`font-size:3rem;`}
      else if(lang == "en"){return css`font-size:5rem;`}
      else if(lang == "ko"){return css`font-size:4.8rem;`}
      else{return css`font-size:5.8rem;`}
    }}
    ${({lang})=>{
      if(lang == "ja"){return css`letter-spacing: 2px;`}
      else if(lang == "zh"){return css`padding:0 50px 0 30px; letter-spacing: 2px;`}
      else if(lang == "vi"){return css`padding:0 20px 0 10px; letter-spacing: 0px;`}
      else{return css`letter-spacing: -2px;`}
    }}
    color: #000;
    background: url(${(props) => {
        if (props.boxBg === 1) {
          return imgDetailTitleMasking01;
        } else if (props.boxBg === 2) {
          return imgDetailTitleMasking02;
        } else if (props.boxBg === 3) {
          return imgDetailTitleMasking03;
        } else if (props.boxBg === 4) {
          return imgDetailTitleMasking04;
        } else if (props.boxBg === 5) {
          return imgDetailTitleMasking05;
        } else if (props.boxBg === 6) {
          return imgDetailTitleMasking06;
        }
      }})
      no-repeat center top;
  }
  img {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  p {
    padding-left: 5px;
    max-width: 730px;
    ${({lang})=>{
      if(lang == "en") {return css`font-size:2.8rem;`}
      else if(lang == "ja"){return css`font-size:2.2rem; letter-spacing: 1px;`}
      else if(lang == "zh"){return css`font-size:2.4rem; letter-spacing: 2px;`}
      else if(lang == "id"){return css`padding-top:6px; font-size:2.2rem;`}
      else if(lang == "th"){return css`font-size:2.4rem;`}
      else if(lang == "vi"){return css`padding-top:4px; font-size:2.1rem;`}
      else{return css`font-size:3.5rem;`}
    }}
    color: #000;
    
  }
  
  @media ${theme.mq.tablet}{
    padding-top:20px;
    background: #fff;
    ::after {
      right: -15px;
      width: 30px;
      height: 30px;
      background-size:cover;
    }
    ::before {
      top: -18px;
      left: -40px;
      width: 100px;
      height: 35px;
      background-size:contain;
    }
    p{
      margin-bottom:20px;
    }
    strong{
      position: static;
      margin-bottom:20px;
    }
  }
  @media ${theme.mq.mobile}{
    strong{
      position: static;
      margin-bottom:20px;
      height:50px;
    }
  }
`;
