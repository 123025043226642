import React from "react";
import styled from "styled-components";
import { iconArrowR } from "../../assets/img";
interface NextArrowProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
// 타입스크립트를 사용하기 때문에 onClick 이벤트를 props로 받아준다.
// className을 받아줄 수 도 있다. 그리고 부모 컴포넌트에서 설정해 줘도 된다.

export default function NextArrow({ onClick }: NextArrowProps) {
  return <NextBtn className="prev-arrow" onClick={onClick}></NextBtn>;
}

const NextBtn = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 78px;
  height: 78px;
  background: url(${iconArrowR}) no-repeat;
  &:hover {
    cursor: pointer;
  }
`;
