import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./styles/global";
import { theme } from "./styles/theme";
import App from './App';
import "./assets/fonts/font.css";
import "../src/lang/i18n";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
        <App />
        </BrowserRouter>
    </ThemeProvider>
);
