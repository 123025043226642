import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import {
  btnCloseMobile,
  btnOpenMobile,
  iconDiscordMobile,
  iconMetaMobile,
  iconTikTokMobile,
  iconYoutubeMobile,
  navOff,
  navOn,
  navOnMobile,
  navOffMobile,
} from "../../assets/img";
import { theme } from "../../styles/theme";
import { useMediaQuery } from "react-responsive";

interface SideNavProps {
  handlePageChange: (number: number) => void;
  currentPage: number;
  isKr: boolean;
}

interface PageActive {
  isHere: boolean;
}

interface navProps {
  navState: boolean;
}

export const PAGEMENU = [
  { name: "Main", key: 0, pageNum: [0] },
  { name: "Community", key: 1, pageNum: [1] },
  { name: "Partner", key: 2, pageNum: [2] },
  { name: "Contents", key: 3, pageNum: [3] },
  { name: "Media", key: 4, pageNum: [4] },
];
export const PAGEMENU_KR = [
  { name: "Main", key: 0, pageNum: [0] },
  { name: "Community", key: 1, pageNum: [1] },
  { name: "Contents", key: 2, pageNum: [2] },
  { name: "Media", key: 3, pageNum: [3] },
];

const SideNav = ({ handlePageChange, currentPage, isKr }: SideNavProps) => {
  const [navState, setNavState] = useState(false);
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const modalHandle = () => {
    setNavState(!navState);
  };
  useEffect(() => {
    navState;
  }, [navState]);

  const handleKrMenu = (kr: boolean) => {
    if (kr) {
      return PAGEMENU_KR;
    }
    return PAGEMENU;
  };

  return (
    <>
      {isTablet ? (
        <NavMobileBox navState={navState}>
          <button onClick={() => modalHandle()}>
            <img src={btnOpenMobile} alt="menu open icon" />
          </button>
          <NavManu navState={navState}>
            <button onClick={() => modalHandle()}>
              <img src={btnCloseMobile} alt="menu close icon" />
            </button>
            <ul className="menu_list">
              <li>
                <Link
                  to="introWrapper"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={0}
                  onClick={() => modalHandle()}
                >
                  Main
                </Link>
              </li>
              {/* <li>
                <Link
                  to="preRegistrationWrapper"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={0}
                  onClick={() => modalHandle()}
                >
                  Pre-Registration
                </Link>
              </li> */}
              <li>
                <Link
                  to="communityEventWrapper"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={0}
                  onClick={() => modalHandle()}
                >
                  Community
                </Link>
              </li>
              {!isKr && (
                <li>
                  <Link
                    to="partnerWrapper"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={0}
                    onClick={() => modalHandle()}
                  >
                    Partner
                  </Link>
                </li>
              )}

              <li>
                <Link
                  to="contentWrapper"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={0}
                  onClick={() => modalHandle()}
                >
                  Contents
                </Link>
              </li>
              <li>
                <Link
                  to="mediaWrapper"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={0}
                  onClick={() => modalHandle()}
                >
                  Media
                </Link>
              </li>
            </ul>
            <ul className="link_list">
              <li>
                <a
                  href="https://discord.gg/carrieverseofficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={iconDiscordMobile} alt="discord icon" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@CarrieVerseOfficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={iconYoutubeMobile} alt="youtube icon" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/CarrieverseGame"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={iconMetaMobile} alt="meta icon" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@carrieverseofficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={iconTikTokMobile} alt="tiktok icon" />
                </a>
              </li>
            </ul>
          </NavManu>
        </NavMobileBox>
      ) : (
        <NavBox>
          {handleKrMenu(isKr).map((menu, i) => (
            <NavItem key={i} isHere={menu.pageNum.includes(currentPage)}>
              <Link
                to={`/#${menu.pageNum}`}
                onClick={() => handlePageChange(menu.key)}
              >
                {menu.name}
              </Link>
            </NavItem>
          ))}
        </NavBox>
      )}
    </>
  );
};

export default SideNav;

const NavBox = styled.ul`
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 2000;
  @media ${theme.mq.header} {
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    transform: none;
  }
  a {
    cursor: pointer;
  }
`;

const NavItem = styled.li<PageActive>`
  padding: 12px 0;
  a {
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 29px;
    height: 24px;
    font-size: 1.8rem;
    color: ${({ isHere }) => (isHere ? "#fff" : "rgba(255,255,255,0.4)")};
    background: url(${({ isHere }) => (isHere ? navOn : navOff)}) no-repeat
      right center;
    transition: all 0.3s ease;
    :hover {
      color: ${({ isHere }) => (isHere ? "#fff" : "rgba(255,255,255,0.6)")};
    }
  }
  @media ${theme.mq.header} {
    padding: 0 20px;
    a {
      padding: 0;
      width: auto;
      height: 60px;
      background: none;
    }
  }
`;

const NavMobileBox = styled.div<navProps>`
  > button {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 999;
  }
  ::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: ${({ navState }) => (navState ? "block" : "none")};
    z-index: 998;
  }
`;

const NavManu = styled.div<navProps>`
  position: fixed;
  right: ${({ navState }) => (navState ? "0" : "-100%")};
  top: 0;
  bottom: 0;
  width: 320px;
  max-width: 100%;
  background-color: rgba(46, 87, 139, 1);
  z-index: 999;
  transition: all ease 0.2s;
  > button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .menu_list {
    position: absolute;
    top: 50%;
    right: 56px;
    transform: translateY(calc(-50% - 30px));
    li {
      margin-bottom: 32px;
      :last-child {
        margin-bottom: 0;
      }
      a {
        display: flex;
        font-size: 20px;
        justify-content: right;
        padding-right: 32px;
        color: rgba(255, 255, 255, 0.4);
        background: url(${navOffMobile}) no-repeat right top 2px;
        transition: all ease 0.2s;
        &.active {
          color: rgba(255, 255, 255, 1);
          background: url(${navOnMobile}) no-repeat right top 2px;
        }
      }
    }
  }
  .link_list {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    li {
      margin: 0 8px;
    }
  }
`;
