import styled from "styled-components";
import {
    partnerBg,
    partnerBgM,
    partnerDh, partnerFingerM,
    partnerDhM, partnerFrameM,
    partnerTitle,
    partnerTitleM,
    slideDetailBg
} from "../../assets/img";
import {useMediaQuery} from "react-responsive";
import {theme} from "../../styles/theme";
import ReactPlayer from "react-player";


interface IPlayProps {
    isYouTubePlay : boolean;
}
const Partner = ({isYouTubePlay}:IPlayProps) => {
    const isTablet = useMediaQuery({
        query: "(max-width: 1024px)",
    });
    const isMobile = useMediaQuery({
        query: "(max-width: 640px)",
    });

    return (
        <PartnerWrapper id="partnerWrapper">
            <Title>
                <img src={isMobile? partnerTitleM:partnerTitle} alt="partner title"/>
            </Title>
            <ContentWrap>
                {isTablet? <><MobileDohyun>
                    <img src={partnerDhM} alt="이도현"/>
                    <img src={partnerFingerM} alt="손가락" className="fixed"/>
                </MobileDohyun></>: <PcDohyun>
                    <img src={partnerDh} alt="이도현"/>
                </PcDohyun>}
                <VideoWrap>
                    <ReactPlayerWrapper>
                        <ReactPlayer
                            url={"https://www.youtube.com/watch?v=91DP4-isf94"}
                            width="100%"// 플레이어 크기 (가로)
                            height="100%" // 플레이어 크기 (세로)
                            playing={isYouTubePlay} // 자동 재생 on
                            muted={false} // 음소거 해제
                            controls={true} // 플레이어 컨트롤 노출 여부
                            light={false} // 플레이어 모드
                            pip={true} // pip 모드 설정 여부
                            loop={true}
                            volume={1}
                            quality={"1080"}
                        />
                    </ReactPlayerWrapper>
                    <VideoTitle>
                        <p>CARRIEVERSE Ambassador</p>
                        <p>LEE DO HYUN</p>
                    </VideoTitle>
                </VideoWrap>

            </ContentWrap>
        </PartnerWrapper>
    );
};

export default Partner;

const PartnerWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url(${partnerBg});
  background-size: cover;
  background-position: top;
  overflow: hidden;
  @media ${theme.mq.tablet}{
    padding: 40px 0 64px;
    width: 100%;
    height: auto;
  }
  @media ${theme.mq.mobile}{
    background: url(${partnerBgM}) no-repeat top;
    background-size:cover;
  }
  
`
const Title = styled.h2`
  margin-bottom: 20px;
    img {
      max-width: 600px;
    }
  @media ${theme.mq.tablet} {
    img{
      max-width: 480px;
    }
  }
  @media ${theme.mq.mobile} {
    img{
      max-width: 273px;
    }
`
const ContentWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${theme.mq.tablet}{
    height: 58.5938vw;
  }
  @media ${theme.mq.mobile} {
    height: 420px;
  }
}
`;

const PcDohyun = styled.div`
  @media (min-width: 1920px){
    top: 2.8125vw;
    left: -17.1875vw;
  }
  position: absolute;
  top: 54px;
  left: -330px;
  width: 23.9583vw;
  min-width: 460px;
  img {
    width: 100%;
  }
`;

const VideoWrap = styled.div`
  @media (min-width: 1920px){
    padding: 40px 30px;
  }
  display: flex;
  flex-direction: column;
  padding: 2.0833vw 1.5625vw;
  min-width: 1000px;
  min-height: 640px;
  width: 52.0833vw;
  height: 33.333312vw;
  background: url(${slideDetailBg});
  background-size: cover;
  overflow: hidden;
  
  @media ${theme.mq.tablet}{
    position: absolute;
    top: 197px;
    padding: 18px 14px 10px;
    min-width: 320px;
    min-height: 220px;
    background: url(${partnerFrameM}) no-repeat top;
    background-size: cover
  }
`;
const ReactPlayerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.3542vw;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media ${theme.mq.tablet}{
    margin-bottom: 10px;
  }
`;
const VideoTitle= styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  p{
    font-family: "MarkPro";
    text-shadow: 0.5px 1px 1px rgba(0, 0, 0, 0.2);
    letter-spacing: -0.04em;
    word-spacing: -1px;
    &:first-child {
      color: #021B49;
      font-size: 1.8rem;
      margin-right: 8px;
    }
    &:last-child {
      font-size: 2.2rem;
      font-weight: 700;
    }
  }
  @media ${theme.mq.mobile}{
    p:first-child{
      font-size: 12px;
    }
    p:last-child{
      font-size: 14px;
    }
  }
`;

const MobileDohyun = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
    img {
      width: 260px;
    }
  img.fixed {
    position: absolute;
    top: 180px;
    left: 50%;
    transform: translateX(-50%);
    width: 360px;
    z-index: 100;
  }
`;
