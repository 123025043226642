import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import langEn from "./lang.en";
import langKo from "./lang.ko";
import langJa from "./lang.ja.json";
import langZh from "./lang.zh.json";
import langId from "./lang.id.json";
import langVi from "./lang.vi.json";
import langTh from "./lang.th.json";

const resources = {
  en: {
    translation: langEn,
  },
  ko: {
    translation: langKo,
  },
  ja: {
    translation: langJa,
  },
  zh: {
    translation: langZh,
  },
  id: {
    translation: langId,
  },
  vi: {
    translation: langVi,
  },
  th: {
    translation: langTh,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    //초기 설정 언어
    lng: "en",
    fallbackLng: "en",
    debug: false,
    defaultNS: "translation",
    ns: "translation",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  })
  .then((r) => r);
