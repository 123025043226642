import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const GlobalStyle = createGlobalStyle`
  ${reset};
  html {
    font-size: 62.5%;
    @media ${({ theme }) => theme.mq.tablet} {
      font-size: 50%;
    }
    @media ${({ theme }) => theme.mq.mobile} {
      font-size: 37.5%;
    }
  }
  body {
    overflow: hidden !important;
    background: white;
    color:#333333;
    font-family: MarkPro,sans-serif;

    * {
      box-sizing: border-box;
      line-height: normal;
    }
    //::-webkit-scrollbar {
    //  display: none;
    //}
    a {
      text-decoration: none;
      color:white;
    }
    button {
      cursor: pointer;
      border: none;
      background: none;
      color: white;
    }
  }
  @media (max-width:1024px){
    body {
      overflow: visible !important;
    }
  }
`;
