import React, { useEffect } from "react";
import Main from "./pages/Main";
import { clickHandler, getBrowserLang } from "./utils/common";
import styled from "styled-components";

function App() {
  useEffect(() => {
    clickHandler(getBrowserLang());
  }, []);

  return <Main />;
}

export default App;
