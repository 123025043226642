import i18next from "i18next";

export const clickHandler = (lang) => {
  i18next.changeLanguage(lang).then((r) => r);
};

export const getBrowserLang = () => {
  const browserLang = navigator.language;
  let settingLang = browserLang;

  if (browserLang === "en-US") { // 영어
    settingLang = "en";
  }
  if (browserLang === "ko-KR") { // 한국
    settingLang = "ko";
  }
  if (browserLang === "ja-JP") { // 일본
    settingLang = "ja";
  }
  if (browserLang === "zh") { // 중국 간체
    settingLang = "zh";
  }
  if (browserLang === "zh-HK") { // 중국 (Hong Kong)
    settingLang = "zh";
  }
  if (browserLang === "zh-MO") { // 중국 (Macau)
    settingLang = "zh";
  }
  if (browserLang === "zh-SG") { // 중국 (Singapore)
    settingLang = "zh";
  }
  if (browserLang === "zh-TW") { // 중국 (T)
    settingLang = "zh";
  }
  if (browserLang === "id") { // 인도 인니어
    settingLang = "id";
  }
  if (browserLang === "id-ID") { // 인도
    settingLang = "id";
  }
  if (browserLang === "vi") { // 베트남어
    settingLang = "vi";
  }
  if (browserLang === "vi-VN") { // 베트남어
    settingLang = "vi";
  }
  if (browserLang === "th") { // 태국어
    settingLang = "th";
  }
  if (browserLang === "th-TH") { // 태국어
    settingLang = "th";
  }
  return settingLang;
};
