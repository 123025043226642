export const SLIDER_DATA =  [
  {
    id:1,
    imgSrc: 'imgContentSlider01.png',
    text: 'GameZone',
    maskSrc : 'imgMasking01.png',
    maskType : 1
  },
  {
    id:2,
    imgSrc: 'imgContentSlider02.png',
    text: 'School',
    maskSrc : 'imgMasking02.png',
    maskType : 2
  },
  {
    id:3,
    imgSrc: 'imgContentSlider03.png',
    text: 'Vehicle',
    maskSrc : 'imgMasking03.png',
    maskType : 3
  },
  {
    id:4,
    imgSrc: 'imgContentSlider04.png',
    text: 'Housing',
    maskSrc : 'imgMasking04.png',
    maskType : 1
  },
  {
    id:5,
    imgSrc: 'imgContentSlider05.png',
    text: 'Fishing',
    maskSrc : 'imgMasking05.png',
    maskType : 4
  },
  {
    id:6,
    imgSrc: 'imgContentSlider06.png',
    text: 'Plaza',
    maskSrc : 'imgMasking06.png',
    maskType : 5
  }
]

export const SLIDER_DETAIL_DATA = [
  {
    id:1,
    imgSrc: 'imgSlideDetail01.png',
    titleText: 'GameZone',
    i18nContentText: 'Game_Zone_Info',
    contentText:'Run, Enjoy, and Win in the Game Zone!',
    maskingSrc : 'imgDetailTitleMasking01.png',
  },
  {
    id:2,
    imgSrc: 'imgSlideDetail02.png',
    titleText: 'School',
    i18nContentText: 'School_Info',
    contentText:'Enjoy School with Educational Games!',
    maskingSrc : 'imgDetailTitleMasking02.png',
  },
  {
    id:3,
    imgSrc: 'imgSlideDetail03.png',
    titleText: 'Vehicle',
    i18nContentText: 'Vehicle_Info',
    contentText:'Cruise and Explore with Your Friends! :)',
    maskingSrc : 'imgDetailTitleMasking03.png',
  },
  {
    id:4,
    imgSrc: 'imgSlideDetail04.png',
    titleText: 'Housing',
    i18nContentText: 'Housing_Info',
    contentText:'Home Parties are Where It\'s At!',
    maskingSrc : 'imgDetailTitleMasking04.png',
  },
  {
    id:5,
    imgSrc: 'imgSlideDetail05.png',
    titleText: 'Fishing',
    i18nContentText: 'Fishing_Info',
    contentText:'Fish for Massive Rewards',
    maskingSrc : 'imgDetailTitleMasking05.png',
  },
  {
    id:6,
    imgSrc: 'imgSlideDetail06.png',
    titleText: 'Plaza',
    i18nContentText: 'Plaza_Info',
    contentText:'The Place to Be When You Want to be Seen',
    maskingSrc : 'imgDetailTitleMasking06.png',
  }
]