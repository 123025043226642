import React, {useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled, { css } from "styled-components";
import {MEDIA_DATA, MEDIA_ITEM_DATA} from "./MediaData";
import CommonModal from "../../components/common/modal/commonModal";
import {theme} from "../../styles/theme";
import ReactPlayer from "react-player";
import useModal from "../../hooks/useModal";
import {useMediaQuery} from "react-responsive";


const MediaSlider = () => {
  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    autoplay: false,
    speed: 500,
    pauseOnHover: false,
    arrows: false,
    cssEase: "linear",

  };

  const getImage = (imgName: string) => require(`../../assets/img/${imgName}`);
  const [isDetailModal, openDetailModal, closDetailModal] = useModal(false);
  const [urlNum, setUrlNum] = useState(0);
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const clickHandler = (index:number) => {
    openDetailModal();
    setUrlNum(index-1);
  }

  return (
    <SliderContents>
      <Slider {...settings}>
        {MEDIA_DATA.map((slider) => (
            <img src={getImage(slider.media_tablet_src)} alt="slide image" key={slider.id} onClick={()=>clickHandler(slider.id)} />
        ))}
      </Slider>
      <CommonModal
          width={isTablet ? "85%" : "372px"}
          minHeight={isTablet ? "auto" : "660px"}
          height={isTablet ? "50%" : "660px"}
          top={"50%"}
          left={"50%"}
          modalIsOpen={isDetailModal}
          closeModal={closDetailModal}
          closeIcon={false}
      >
        <ReactPlayerWrapper>
          <ReactPlayer
              url={MEDIA_ITEM_DATA[urlNum].src}
              width="100%" // 플레이어 크기 (가로)
              height="100%" // 플레이어 크기 (세로)
              playing={true} // 자동 재생 on
              muted={true} // 자동 재생 on
              controls={false} // 플레이어 컨트롤 노출 여부
              light={false} // 플레이어 모드
              pip={true} // pip 모드 설정 여부
              loop={true}
              quality={"1080"}
              config={{
                youtube: {
                  playerVars: { showinfo: 1 },
                },
              }}
              poster={
                "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg"
              } // 플레이어 초기 포스터 사진
          />
        </ReactPlayerWrapper>
      </CommonModal>
    </SliderContents>
  );
};

export default MediaSlider;

const SliderContents = styled.div`
  height:auto;
  .slick-slider {
    .slick-list{
      margin-bottom:40px;
      width:500px;
      max-width: 100%;
      .slick-slide{
        //padding:0 10px;
      }
    }
    .slick-dots{
      position: static;
      display: flex !important;
      justify-content: center;
      li{
        display: block;
        margin: 0 5px;
        width: 8px;
        height:8px;
        button{
          padding:0;
          width: 8px;
          height:8px;
          background-color: rgba(255, 255, 255, 0.4);
          border-radius:100%;
          &::before {
            display: none;
          }
        }
        &.slick-active button{
          background-color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  @media ${theme.mq.mobile}{
    .slick-slider {
      .slick-list{
        width:320px;
      }
    }
  }
`;

const ReactPlayerWrapper = styled.div`
  width: 100%;
  height: 100%;

  pointer-events: none;
`;