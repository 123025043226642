import React, { useEffect, useState } from "react";
import { MEDIA_DATA, MEDIA_ITEM_DATA } from "./MediaData";
import styled from "styled-components";
import useModal from "../../hooks/useModal";
import CommonModal from "../../components/common/modal/commonModal";
import {
  mediaTitle,
  mediaBg,
  imgMediaFrame,
  mediaBgtablet,
  mediaTitleMobile,
} from "../../assets/img";
import ReactPlayer from "react-player";
import { useMediaQuery } from "react-responsive";
import MediaSlider from "./MediaSlider";
import { theme } from "../../styles/theme";

interface ItemProps {
  itemNum: number;
}

interface DataProps {
  id: number;
  light_src: string;
  media_src: string;
  media_tablet_src: string;
}

const Media = () => {
  const getImage = (imgName: string) => require(`../../assets/img/${imgName}`);
  const [itemNum, setItemNum] = useState(0);
  const [isOverlay, setIsOverlay] = useState(false);
  const [urlNum, setUrlNum] = useState(0);
  const [isDetailModal, openDetailModal, closDetailModal] = useModal(false);
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 640px)",
  });

  const overlayHandle = () => {
    setTimeout(() => {
      if (!isDetailModal) {
        setIsOverlay(false);
      } else {
        setIsOverlay(true);
      }
    }, 0);
  };

  const clickHandler = (index: number) => {
    openDetailModal();
    setUrlNum(index - 1);
  };

  useEffect(() => {
    setTimeout(() => {
      if (itemNum === 0) {
        setIsOverlay(false);
      } else {
        setIsOverlay(true);
      }
    }, 0);
  }, [isDetailModal]);

  return (
    <Mediabox id="mediaWrapper">
      <h2>
        <img
          src={isMobile ? mediaTitleMobile : mediaTitle}
          alt={`${mediaTitle} image`}
        />
      </h2>
      {isTablet ? (
        <MediaSlider />
      ) : (
        <>
          <MediaFrameOn itemNum={itemNum} onMouseLeave={() => overlayHandle()}>
            {MEDIA_DATA.map((item: DataProps) => (
              <span
                key={item.id}
                onMouseOver={() => {
                  setItemNum(item.id);
                  setIsOverlay(true);
                }}
                onMouseOut={() => setItemNum(0)}
                onClick={() => clickHandler(item.id)}
              >
                <img src={getImage(item.light_src)} alt={`light0${item.id}`} />
              </span>
            ))}
          </MediaFrameOn>
          <MediaFrameOff itemNum={itemNum}>
            {MEDIA_DATA.map((item: DataProps) => (
              <img
                key={item.id}
                src={getImage(item.media_src)}
                alt={`media item image0${item.id}`}
                className={item.id === itemNum ? "active" : ""}
              />
            ))}
            {isOverlay && <Overlay />}
          </MediaFrameOff>
        </>
      )}

      {isOverlay && (
        <CommonModal
          width={"372px"}
          minHeight={"660px"}
          height={"660px"}
          top={"50%"}
          left={"50%"}
          modalIsOpen={isDetailModal}
          closeModal={closDetailModal}
          closeIcon={true}
        >
          <ReactPlayerWrapper>
            <ReactPlayer
              url={MEDIA_ITEM_DATA[urlNum].src}
              width="100%" // 플레이어 크기 (가로)
              height="100%" // 플레이어 크기 (세로)
              playing={true} // 자동 재생 on
              muted={false} // 자동 재생 on
              volume={1}
              controls={false} // 플레이어 컨트롤 노출 여부
              light={false} // 플레이어 모드
              pip={true} // pip 모드 설정 여부
              loop={true}
              quality={"1080"}
              config={{
                youtube: {
                  playerVars: { showinfo: 1 },
                },
              }}
              poster={
                "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg"
              } // 플레이어 초기 포스터 사진
            />
          </ReactPlayerWrapper>
        </CommonModal>
      )}
    </Mediabox>
  );
};

export default Media;

const Mediabox = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  text-align: center;
  height: 100vh;
  background: url(${mediaBg}) no-repeat center;
  background-size: cover;
  h2 {
    margin-bottom: 20px;
    width: 100%;
  }
  @media ${theme.mq.tablet} {
    padding: 50px 0 72px;
    background: url(${mediaBgtablet}) no-repeat center;
    background-size: cover;
    h2 {
      img {
        width: 190px;
      }
    }
  }
  @media ${theme.mq.mobile} {
    height: 100vh;
    h2 {
      img {
        max-width: 94px;
      }
    }
  }
`;
const MediaFrameOff = styled.div<ItemProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  padding: 48px 25px 25px;
  width: 956px;
  height: 684px;
  background: url(${imgMediaFrame}) no-repeat center;
  box-sizing: border-box;
  img {
    position: relative;
    z-index: 0;
    &.active {
      z-index: 1000;
    }
  }
  @media (max-height: 1000px) and (min-height: 760px) {
    margin-top: -100px;
    transform: scale(0.7);
  }
`;
const MediaFrameOn = styled.div<ItemProps>`
  position: absolute;
  top: calc(50% + 72px);
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  padding: 28px 25px 25px;
  width: 956px;
  height: 684px;
  box-sizing: border-box;
  z-index: 2000;
  @media (max-height: 1000px) and (min-height: 760px) {
    top: calc(50% + 22px);
    transform: translate(-50%, -50%) scale(0.7);
  }
  span {
    text-align: center;
    width: 270px;
    height: 293px;
    :nth-child(n + 4) {
      img {
        margin-top: -5px;
      }
    }
    overflow: hidden;
    img {
      height: 0;
      opacity: 0;
    }
    :hover {
      cursor: pointer;
      img {
        height: 317px;
        opacity: 1;
        transition: all 0.2s ease;
      }
    }
  }
`;
const Overlay = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 500;
`;
const ReactPlayerWrapper = styled.div`
  width: 100%;
  height: 100%;

  //pointer-events: none;
`;
