import React, { useEffect } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import {btnClose, btnCloseMobile} from "../../../assets/img";
import { useModalStore } from "../../../store/ModalStore";
import {theme} from "../../../styles/theme";

interface IModalStyleProps {
  minHeight: string;
  maxHeight?:string;
  height?: string;
  width: string;
  maxWidth?: string;
  transform?: string;
  top: string;
  left: string;
  background?: string;
  closeIcon? : boolean;
}

interface ModalProps {
  width: string;
  maxWidth?: string;
  minHeight: string;
  maxHeight?:string;
  height?: string;
  transform?: string;
  top?: string;
  left: string;
  background?: string;
  children: React.ReactNode;
  modalIsOpen: boolean;
  closeModal: () => void;
  changePhase?: (str: string) => void;
  closeIcon? : boolean;
}

export const customStyles = {
  overlay: {
    zIndex: "9999",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
};

const CommonModal = ({
  children,
  minHeight,
  maxHeight,
  width,
  maxWidth,
  height,
  background,
  top,
  left,
  transform,
  closeModal,
  modalIsOpen,
  changePhase,
  closeIcon
}: ModalProps) => {
  const { setIsModalOpen } = useModalStore();

  useEffect(() => {
    if (modalIsOpen) {
      setIsModalOpen(true);
    }
  }, [modalIsOpen]);

  const handleClose = () => {
    if (changePhase) changePhase("");
    setIsModalOpen(false);
    closeModal();
  };

  return (
    <ModalWrapper
      width={width}
      maxWidth={maxWidth}
      height={height ? height : "auto"}
      minHeight={minHeight}
      maxHeight={maxHeight}
      background={background}
      top={top ? top : "50%"}
      left={left}
      transform={transform ? transform : "translate(-50%, -50%)"}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={handleClose}
      onAfterOpen={() => {
        document.body.style.overflow = "hidden";
      }}
      onAfterClose={() => {
        document.body.style.overflow = "unset";
      }}
      style={customStyles}
    >
      <CloseButton onClick={handleClose}>
        <CustomImg src={closeIcon ? btnClose : btnCloseMobile } alt="closeIcon" />
      </CloseButton>
      <ChildrenArea>{children}</ChildrenArea>
    </ModalWrapper>
  );
};
export default CommonModal;

const ModalWrapper = styled(Modal)<IModalStyleProps>`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  transform: ${(props) => props.transform};
  display: flex;
  flex-direction: column;
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  background-color: ${(props) => props ? props.background : "black"};
  outline: none;
  color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  z-index: 9999;
`;

const ChildrenArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CloseButton = styled.button`
  z-index: 9999;
`;

const CustomImg = styled.img`
  position: absolute;
  top: 0;
  left:calc(100% + 5px);
  @media ${theme.mq.tablet}{
    top: 0;
    left:auto;
    right:0;
    transform: translateY(calc(-100% - 10px));
  }
`;
